<template>
  <v-container fluid class="mt-8">
    <v-row justify="start" class="mx-1">
      <v-col cols="12">
        <h1>Pesquisa de Beneficiário</h1>
      </v-col>
    </v-row>
    <v-form class="mt-8">
      <v-row justify="start" class="mx-1">
        <v-col cols="12" xs="12" sm="6" md="3" lg="2">
          <v-autocomplete
            v-model="financialGroupIds"
            :items="financialGroups"
            :loading="loadingFinancialGroups"
            :disabled="loadingFinancialGroups"
            item-text="name"
            item-value="id"
            color="textPrimary"
            outlined
            multiple
            label="Grupo Financeiro"
            :rules="financialGroupIds && financialGroupIds.length > 0 ? [rule.handleSelectionLimit(financialGroupIds, 5)] : []"
            chips
            small-chips
            clearable
            :search-input.sync="financialGroupIdsSearch"
            @change="limiteCategory('financialGroups'); financialGroupIdsSearch = null"
          >
            <template v-slot:selection="data">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip class="mb-2" v-on="on" v-bind="attrs"> {{ data.item.name }} </v-chip>
                </template>
                <span>{{ data.item.name }}</span>
              </v-tooltip>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col v-if="!isRHProtegido" cols="12" xs="12" sm="6" md="3" lg="2">
          <v-autocomplete
            v-model="benefitCodes"
            :items="benefits"
            :loading="loadingBenefits"
            :disabled="loadingBenefits"
            item-text="name"
            item-value="xipp_contract_record_type_code"
            label="Benefício"
            color="textPrimary"
            outlined
            multiple
            :rules="benefitCodes && benefitCodes.length > 0 ? [rule.handleSelectionLimit(benefitCodes, 5)] : []"
            chips
            small-chips
            clearable
            :search-input.sync="benefitCodesSearch"
            @change="limiteCategory('benefits'); benefitCodesSearch = null"
          >
            <template v-slot:selection="data">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip class="mb-2" v-on="on" v-bind="attrs"> {{ data.item.name }} </v-chip>
                </template>
                <span>{{ data.item.name }}</span>
              </v-tooltip>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col v-if="!isRHProtegido" cols="12" xs="12" sm="6" md="3" lg="2">
          <v-autocomplete
            v-model="insuranceCarrierIds"
            :items="insuranceCarriers"
            :loading="loadingInsuranceCarriers"
            :disabled="loadingInsuranceCarriers"
            item-text="xipp_commercial_name"
            item-value="id"
            color="textPrimary"
            outlined
            multiple
            label="Operadora"
            :rules="insuranceCarrierIds && insuranceCarrierIds.length > 0 ? [rule.handleSelectionLimit(insuranceCarrierIds, 5)] : []"
            chips
            small-chips
            clearable
            :search-input.sync="insuranceCarrierIdsSearch"
            @change="limiteCategory('insuranceCarriers'); insuranceCarrierIdsSearch = null"
          >
            <template v-slot:selection="data">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip class="mb-2" v-on="on" v-bind="attrs"> {{ data.item.xipp_commercial_name }} </v-chip>
                </template>
                <span>{{ data.item.xipp_commercial_name }}</span>
              </v-tooltip>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="3" lg="2">
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-autocomplete
                  v-model="contractIds"
                  :items="contracts"
                  item-value="id"
                  item-text="text"
                  label="Contrato"
                  outlined
                  color="textPrimary"
                  :disabled="isDisableContract"
                  :loading="loadingContracts"
                  @input="getSubContractsByContractId(contractIds)"
                  clearable
                  :search-input.sync="contractIdsSearch"
                  @change="contractIdsSearch = null"
                />
              </div>
            </template>
            <span>{{ captureContractText() }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="3" lg="2">
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-autocomplete
                  v-model="subContractIds"
                  :items="subContracts"
                  item-value="id"
                  item-text="text"
                  label="Sub-Contrato"
                  outlined
                  color="textPrimary"
                  :disabled="verifyContractForDisableField(contractIds)"
                  :loading="loadingSubContracts"
                  clearable
                  :search-input.sync="subContractIdsSearch"
                  @change="subContractIdsSearch = null"
                />
              </div>
            </template>
            <span>{{ captureSubContractText() }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row justify="start" class="mx-1">
        <v-col cols="12" xs="12" sm="6" md="3" lg="2">
          <v-autocomplete
            v-model="insuredType"
            :items="benefitTypes"
            item-value="value"
            item-text="text"
            label="Tipo do Beneficiário"
            outlined
            color="textPrimary"
            :loading="loadingBenefitTypes"
            class="mt-2"
            :search-input.sync="insuredTypeSearch"
            @change="insuredTypeSearch = null"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="3" lg="2">
          <v-autocomplete
            v-model="insuredSituation"
            :items="situations"
            item-text="text"
            item-value="value"
            label="Situação"
            outlined
            color="textPrimary"
            class="mt-2"
            :search-input.sync="insuredSituationSearch"
            @change="insuredSituationSearch = null"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="3" lg="2">
          <v-text-field
            v-model.trim="name"
            label="Nome do Beneficiário"
            outlined
            color="textPrimary"
            class="mt-2"
            :rules="name ? [rule.name, rule.validateIsAlphanumeric] : []"
            validate-on-blur
            @keyup.enter="onClickSearch('filter')"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="3" lg="2">
          <v-text-field
            v-model="cpf"
            label="CPF do Beneficiário"
            outlined
            color="textPrimary"
            class="mt-2"
            placeholder="Informe o número"
            v-mask="'###.###.###-##'"
            :rules="cpf ? [rule.cpf] : []"
            validate-on-blur
            @keyup.enter="onClickSearch('filter')"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="3" lg="2">
          <v-btn
            class="white--text mt-2"
            style="height: 55px;"
            x-large
            block
            color="textPrimary"
            @click="onClickSearch('filter')"
            :loading="loadingInsureds"
          >
            Pesquisar
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-row justify="start">
      <v-col cols="12">
        <v-card style="background-color: #f4f4f4;" class="mt-10 mx-4">
          <v-card-title>
            <v-col offset-md="6" offset-lg="7" offset-xl="8" />
            <v-text-field
              v-model="searchTable"
              outlined
              dense
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
              class="expanded-input"
              @click="expandTextField"
              @blur="resetTextFieldWidth"
              :style="{ width: `${textFieldWidth}px` }"
            />
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="insureds"
            :search="searchTable"
            :loading="loadingInsureds"
            hide-default-footer
          >
          <template v-slot:[`item.insuredName`]="{ item }">
            <span class="text--truncate">{{ item.insuredName ? formatter.formatToTitleCase(item.insuredName) : '-' }}</span>
          </template>
          <template v-slot:[`item.insuredCpf`]="{ item }">
            <span class="text--truncate">{{ item.insuredCpf ? formatter.formatCpf(item.insuredCpf) : '-' }}</span>
          </template>
          <template v-slot:[`item.insuredType`]="{ item }">
            <span class="text--truncate">{{ item.insuredType === 'HOLDER' ? 'Titular' : 'Dependente' }}</span>
          </template>
          <template v-slot:[`item.holderName`]="{ item }">
            <span>{{ item.holderName ? formatter.formatToTitleCase(item.holderName) : '-' }}</span>
          </template>
          <template v-slot:[`item.financialGroupName`]="{ item }">
            <span class="text--truncate">{{ item.financialGroupName ? item.financialGroupName : '-' }}</span>
          </template>
          <template v-slot:[`item.benefitName`]="{ item }">
            <span class="text--truncate">{{ item.benefitName ? item.benefitName : '-' }}</span>
          </template>
          <template v-slot:[`item.insuranceCarrierName`]="{ item }">
            <span>{{ item.insuranceCarrierName ? item.insuranceCarrierName : '-' }}</span>
          </template>
          <template v-slot:[`item.contractNumber`]="{ item }">
            <span class="text--truncate">{{ item.contractNumber ? item.contractNumber : '-' }}</span>
          </template>
          <template v-slot:[`item.subContractNumber`]="{ item }">
            <span class="text--truncate">{{ item.subContractNumber ? item.subContractNumber : '-' }}</span>
          </template>
          <template v-slot:[`item.planName`]="{ item }">
            <span class="text--truncate">{{ item.planName ? item.planName : '-' }}</span>
          </template>
          <template v-slot:[`item.startDate`]="{ item }">
            <span class="text--truncate">{{ item.startDate ? formatter.formatDate(item.startDate) : '-' }}</span>
          </template>
          <template v-slot:[`item.endDate`]="{ item }">
            <span class="text--truncate">{{ item.endDate ? formatter.formatDate(item.endDate) : '-' }}</span>
          </template>
          <template v-slot:[`item.cardNumber`]="{ item }">
            <span class="text--truncate">{{ item.cardNumber ? item.cardNumber : '-' }}</span>
          </template>
          <template v-slot:[`item.hasGracePeriod`]="{ item }">
            <span class="text--truncate">{{ item.hasGracePeriod ? 'Sim' : 'Não' }}</span>
          </template>
          <template v-slot:[`item.situation`]="{ item }">
            <v-chip :color="getColor(item.situation)" dark>
              <span class="text--bold text--truncate">{{ getSituation(item.situation) }}</span>
            </v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu transition="slide-y-transition" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="textPrimary"
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <TodoListActions :actions="getActionsForItem(item)" />
            </v-menu>
          </template>
          </v-data-table>
          <div class="float-right pt-2">
            <v-pagination
              v-model="page"
              color="textPrimary"
              :length="totalPages"
              :total-visible="9"
              :disabled="loadingInsureds"
              @input="onClickSearch('pagination')"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar.show"
      timeout="10000"
      top
      elevation="24"
      :color="snackbar.type"
    >
      <div class="text-center">{{ snackbar.text }}</div>
      <template v-slot:action="{ attrs }">
        <v-icon
          text
          dark
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          close
        </v-icon>
      </template>
    </v-snackbar>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="120">
        <div>Carregando...</div>
      </v-progress-circular>
    </v-overlay>

    <v-footer class="my-10">
      <v-row class="d-flex justify-end">
        <v-col cols="12" xs="12" sm="12" md="2" lg="2">
          <v-btn
            x-large
            block
            outlined
            color="textPrimary"
            @click="onClickBack()"
          >
            Voltar
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>

    <v-dialog v-model="editBeneficiaryDateModal" persistent max-width="800">
      <v-card width="800" class="modalExportMovementFiltersTopCenter">
        <v-card-title class="text-h5 font-weight-bold" style="color: #3b495b;">
          As informações editadas não serão encaminhadas para a Operadora. Será apenas atualizado o cadastro do beneficiário.
        </v-card-title>

        <v-divider color="#ccc" />

        <v-card-text class="mt-5">
          <v-form ref="formBeneficiaryDate">
            <v-row justify="start">
              <v-col cols="6">
                <v-text-field
                  label="Data Início Vigência"
                  class="mt-2"
                  outlined
                  v-model="beneficiaryEdit.startDate"
                  append-icon="fas fa-calendar-alt"
                  placeholder="DD/MM/YYYY"
                  v-mask="'##/##/####'"
                  color="textPrimary"
                  :rules="[rule.isDateValid]"
                  validate-on-blur
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Data Fim Vigência"
                  class="mt-2"
                  outlined
                  v-model="beneficiaryEdit.endDate"
                  append-icon="fas fa-calendar-alt"
                  placeholder="DD/MM/YYYY"
                  v-mask="'##/##/####'"
                  color="textPrimary"
                  :rules="[rule.isDateValid, rule.endDateIsGreaterThanOrEqualToStartDate(beneficiaryEdit.startDate, beneficiaryEdit.endDate, 'Data final deve ser maior ou igual a data inicial.')]"
                  validate-on-blur
                />
              </v-col>
              <v-col
                cols="6"
                class="py-0">
                <v-text-field
                  label="Carteirinha"
                  class="mt-2"
                  outlined
                  v-model="beneficiaryEdit.cardNumber"
                  color="textPrimary"
                />
              </v-col>
              <v-col
                cols="6"
                class="py-0">
                <v-text-field
                  label="Carteirinha Congênere"
                  class="mt-2"
                  outlined
                  v-model="beneficiaryEdit.congenericCard"
                  color="textPrimary"
                  :disabled="isDisableCongenericCard"
                />
              </v-col>
              <v-col
                cols="6"
                class="py-0">
                <v-text-field
                  label="Código Família "
                  class="mt-2"
                  outlined
                  v-model="beneficiaryEdit.familyCode"
                  :rules="beneficiaryEdit.familyCode ? [rule.sizeLimit(beneficiaryEdit.familyCode,100),rule.hasEmptyCaracter, rule.isAlphanumeric] : []"
                  validate-on-blur
                  color="textPrimary"
                />
              </v-col>
              <v-col
                cols="6"
                class="py-0">
                <v-text-field
                  label="Sequencial"
                  class="mt-2"
                  outlined
                  v-model="beneficiaryEdit.familyCodeSeq"
                  :rules="beneficiaryEdit.familyCodeSeq ? [rule.sizeLimit(beneficiaryEdit.familyCodeSeq,100),rule.hasEmptyCaracter, rule.isAlphanumeric] : []"
                  validate-on-blur
                  color="textPrimary"                  
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" width="150px" outlined @click="closeEditBeneficiaryDate()">
            Fechar
          </v-btn>
          <v-btn color="primary" width="150px" @click="confirmEditBeneficiaryDate()" :loading="loadingEditDate">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';
import InsuranceCarriersMixin from '@/shared/mixins/sdi/insuranceCarriersMixin';
import ContractsMixin from '@/shared/mixins/sdi/contractsMixin';
import BenefitServiceV2 from '@/services-http/odoo/BenefitServiceV2';
import Rules from '@/shared/validators/formRules';
import InsuredService from '@/services-http/contract/InsuredService';
import CarrierService from '@/services-http/odoo/CarrierService';
import Formatter from '@/shared/formatters/formatters';
import lodash from 'lodash';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import TodoListActions from '@/components/TodoList/TodoListActions.vue';
import authorityManager from '@/shared/models/authority-manager.model';
import { mapMutations, mapGetters } from 'vuex';

export default {

  components: {
    TodoListActions
  },

  data: () => ({
    financialGroupIdsSearch: null,
    benefitCodesSearch: null,
    insuranceCarrierIdsSearch: null,
    contractIdsSearch: null,
    subContractIdsSearch: null,
    insuredTypeSearch: null,
    insuredSituationSearch: null,
    beneficiaryEdit: {
      startDate: '',
      endDate: '',
      insuredType: '',
      cardNumber: '',
      congenericCard: '',
      contractedPlanId: null,
      familyCode: '',
      familyCodeSeq: '',
    },
    loadingFinancialGroups: false,
    loadingBenefits: false,
    loadingInsuranceCarriers: false,
    loadingContracts: false,
    loadingSubContracts: false,
    loadingBenefitTypes: false,
    loadingInsureds: false,
    loadingEditDate: false,
    overlay: false,
    isRHProtegido: false,
    isDisableContract: true,
    isDisableCongenericCard: true,

    insuredType: '',
    insuredSituation: '',
    searchTable: null,
    name: '',
    cpf: '',

    snackbar: {
      show: false,
      type: '',
      text: '',
    },

    financialGroupIds: [],
    benefitCodes: [],
    insuranceCarrierIds: [],
    contractIds: [],
    subContractIds: [],
    financialGroups: [],
    benefits: [],
    insuranceCarriers: [],
    contracts: [],
    subContracts: [],
    plans: [],
    // insureds: [],
    benefitTypes: [
      { text: 'Ambos', value: null },
      { text: 'Dependente', value: 'DEPENDENT' },
      { text: 'Titular', value: 'HOLDER' },
    ],
    situations: [
      { text: 'Ambos', value: null },
      { text: 'Ativo', value: 'ACTIVE' },
      { text: 'Inativo', value: 'INACTIVE' },
    ],
    headers: [
      {
        text: 'Nome Beneficiário', class: 'text--truncate', align: 'start', value: 'insuredName', sortable: false,
      },
      {
        text: 'CPF Beneficiário', class: 'text--truncate', align: 'center', value: 'insuredCpf', sortable: false,
      },
      {
        text: 'Tipo Beneficiário', class: 'text--truncate', align: 'start', value: 'insuredType', sortable: false,
      },
      {
        text: 'Nome Titular', class: 'text--truncate', align: 'start', value: 'holderName', sortable: false,
      },
      {
        text: 'Grupo Financeiro', class: 'text--truncate', align: 'start', value: 'financialGroupName', sortable: false,
      },
      {
        text: 'Benefício', class: 'text--truncate', align: 'start', value: 'benefitName', sortable: false,
      },
      {
        text: 'Operadora', class: 'text--truncate', align: 'start', value: 'insuranceCarrierName', sortable: false,
      },
      {
        text: 'Contrato', class: 'text--truncate', align: 'center', value: 'contractNumber', sortable: false,
      },
      {
        text: 'Sub-Contrato', class: 'text--truncate', align: 'center', value: 'subContractNumber', sortable: false,
      },
      {
        text: 'Plano', class: 'text--truncate', align: 'center', value: 'planName', sortable: false,
      },
      {
        text: 'Início Vigência', class: 'text--truncate', align: 'center', value: 'startDate', sortable: false,
      },
      {
        text: 'Fim Vigência', class: 'text--truncate', align: 'center', value: 'endDate', sortable: false,
      },
      {
        text: 'Número Cartão', class: 'text--truncate', align: 'center', value: 'cardNumber', sortable: false,
      },
      {
        text: 'Situação', class: 'text--truncate', align: 'center', value: 'situation', sortable: false,
      },
      {
        text: 'Ações', class: 'text--truncate', align: 'center', value: 'actions', sortable: false,
      },
    ],

    textFieldWidth: 100,
    page: 1,
    totalPages: 0,
    editBeneficiaryDateModal: false,
    hasAuthorityEditBeneficiaryDate: false,
  }),

  async mounted() {
    this.verifyRouteRHProtegidoMixin();
    if (!this.isRHProtegido) {
      // Ajuste paleativo feito para entrega da SEBE.
      this.getInsuranceCarriers();
      this.loadBenetiType();
    }
    this.returnLoading();
    await this.getFinancialGroups();

    if (this.financialGroups && this.financialGroups.length === 1) {
      this.financialGroupIds = [ this.financialGroups[0].id ];
    }
  },

  watch: {
    financialGroupIds: {
      deep: true,
      handler(val) {
        const financialGroupIds = lodash.cloneDeep(val);
        if (financialGroupIds && financialGroupIds.length !== 0) {
          this.getContractsByFinancialGroupIdVerifyRhProtegido(financialGroupIds, this.isRHProtegido);
          this.isDisableContract = false;
        } else {
          this.contracts = [];
          this.contractIds = [];
          this.subContracts = [];
          this.subContractIds = [];
          this.isDisableContract = true;
        }
      },
    },
    contractIds: {
      deep: true,
      handler(val) {
        if (typeof val !== 'number') {
          this.subContractIds = [];
          this.subContracts = [];
        }
      },
    },
  },

  mixins: [
    FinancialGroupsMixin,
    ContractsMixin,
    InsuranceCarriersMixin,
    VerifyRoutesMixin,
  ],

  methods: {
    ...mapMutations({
      resetInsuredModule: 'insured/resetModule',

      setContractedPlanId: 'insured/setContractedPlanId',
      setInsureds: 'insured/setInsureds',

      setSearchParams: 'handler/setSearchParams',
    }),

    returnLoading() {
      if (this.insureds.length > 0 && this.searchParams) {
        const searchParams = this.searchParams;
        if (searchParams) {
          this.page = searchParams.page;
          this.totalPages = searchParams.totalPages;
          this.insuredType = searchParams.insuredType;
          this.insuredSituation = searchParams.insuredSituation;
          this.name = searchParams.name;
          this.cpf = searchParams.cpf;
          this.financialGroupIds = searchParams.financialGroupIds;
          this.benefitCodes = searchParams.benefitCodes;
          this.insuranceCarrierIds = searchParams.insuranceCarrierIds;
          this.contractIds = searchParams.contractIds;
          this.subContractIds = searchParams.subContractIds;
          if (typeof searchParams.contractIds === 'number') {
            this.getSubContractsByContractId(this.contractIds);
          }
        }
      }
    },
    async loadBenetiType() {
      const benefitService = new BenefitServiceV2();
      this.loadingBenefits = true;
      benefitService.FindAll().then((response) => {
        if (response && response.data && response.data.length) {
          this.benefits = response.data.filter((benefit) => benefit.xipp_contract_record_type_code !== null);
        }
        this.loadingBenefits = false;
      }).catch(() => {
        this.loadingBenefits = false;
      });
    },
    showDetailInsured(item) {
      const insuredType = item.insuredType.toLowerCase();
      this.setContractedPlanId(Number(item.contractedPlanId));
      sessionStorage.setItem('contractedPlanId', JSON.stringify(item.contractedPlanId));
      this.redirectRouter('InsuredDetail', { isRHProtegido: this.isRHProtegido }, { insuredType });
    },
    editBeneficiaryDate(item) {
      this.editBeneficiaryDateModal = true;
      this.isDisableCongenericCard = true;
      this.beneficiaryEdit.startDate = this.formatter.formatDate(item.startDate);
      this.beneficiaryEdit.endDate = this.formatter.formatDate(item.endDate);
      this.beneficiaryEdit.insuredType = item.insuredType;
      this.beneficiaryEdit.contractedPlanId = item.contractedPlanId;
      this.beneficiaryEdit.cardNumber = item.cardNumber;
      this.beneficiaryEdit.congenericCard = item.congenericCard;
      this.beneficiaryEdit.familyCode = item.familyCode;
      this.beneficiaryEdit.familyCodeSeq = item.familyCodeSeq;

      this.verifyCongenericCard(item.insuranceCarrierId);
    },
    closeEditBeneficiaryDate() {
      this.beneficiaryEdit = {
        startDate: '',
        endDate: '',
        insuredType: '',
        cardNumber: '',
        congenericCard: '',
        contractedPlanId: null,
        familyCode: '',
        familyCodeSeq: null
      };
      this.editBeneficiaryDateModal = false;
    },
    async confirmEditBeneficiaryDate() {
      if (!this.$refs.formBeneficiaryDate.validate()) return
      if (!this.beneficiaryEdit.startDate || !this.rule.isDateValid(this.beneficiaryEdit.startDate)) {
        this.customizeSnackbarMessage('error', 'Preencha a data inicial efetiva para continuar.');
        return;
      }

      if (!this.rule.isDateValid(this.beneficiaryEdit.startDate)) {
        this.customizeSnackbarMessage('error', 'Data inicial inválida.');
        return;
      }

      try {
        const data = {
          contractedPlanId: this.beneficiaryEdit.contractedPlanId,
          startDate: this.formatter.formatDateBRtoString(this.beneficiaryEdit.startDate),
          endDate: this.beneficiaryEdit.endDate ? this.formatter.formatDateBRtoString(this.beneficiaryEdit.endDate) : null,
          insuredType: this.beneficiaryEdit.insuredType,
          cardNumber: this.beneficiaryEdit.cardNumber,
          congenericCard: this.beneficiaryEdit.congenericCard,
          familyCode: this.beneficiaryEdit.familyCode ? this.beneficiaryEdit.familyCode.toUpperCase() : null,
          familyCodeSeq: this.beneficiaryEdit.familyCodeSeq ? this.beneficiaryEdit.familyCodeSeq.toUpperCase() : null
        };
        this.loadingEditDate = true;
        await this.insuredService.updateBeneficiaryDate(data);
        this.customizeSnackbarMessage('success', 'Beneficiário atualizado com sucesso');
        this.onClickSearch('filter');
      } catch {
        this.customizeSnackbarMessage('error', 'Ocorreu um erro ao tentar atualizar a data efetiva do beneficiário.');
      } finally {
        this.editBeneficiaryDateModal = false;
        this.loadingEditDate = false;
      }
    },
    validationFamilyCode() {
      if (this.$route.query.method === 'isNew') {
        if (this.familyCode.length >= 1) {
          this.familyCode='';
          this.customizeSnackbarMessage('error', 'O tipo de elegibilidade selecionado, só é permitido um plano');
        }
      } else if (this.$route.query.method === 'isEdit') {
        if (this.familyCode > 1) {
          this.isDisableSave = true;
          this.customizeSnackbarMessage('error', 'O tipo de elegibilidade selecionado, só é permitido um plano');
        } else {
          this.isDisableSave = false;
        }
      }
    },
    getColor(situation) {
      if (situation === 'ACTIVE') return 'green';
      if (situation === 'INACTIVE') return 'red';
      return 'red';
    },
    getSituation(situation) {
      if (situation === 'ACTIVE') return 'Ativo';
      if (situation === 'INACTIVE') return 'Inativo';
      return 'Inativo';
    },
    expandTextField() {
      this.textFieldWidth = 400;
    },
    resetTextFieldWidth() {
      this.textFieldWidth = 100;
    },
    limiteCategory(category) {
      if (category === 'financialGroups' && this.financialGroupIds.length > 5) this.financialGroupIds.pop();
      if (category === 'insuranceCarriers' && this.insuranceCarrierIds.length > 5) this.insuranceCarrierIds.pop();
      if (category === 'benefits' && this.benefitCodes.length > 5) this.benefitCodes.pop();
    },
    captureContractText() {
      let contractFound = '-';
      if ((this.contracts && this.contracts.length > 0) && (this.contractIds)) {
        contractFound = this.contracts.find((contract) => contract.id === Number(this.contractIds));
        if (contractFound) {
          return (`${(contractFound.benefit_id ? contractFound.benefit_id : '-')} - ${(contractFound.carrier_id && contractFound.carrier_id.xipp_commercial_name ? contractFound.carrier_id.xipp_commercial_name : '-')} - ${contractFound.policy ? contractFound.policy : '-'} - ${contractFound.contract_owner_id && contractFound.contract_owner_id.legal_name ? contractFound.contract_owner_id.legal_name : '-'}`);
        }
      }
      return contractFound;
    },
    captureSubContractText() {
      let subContractFound = '-';
      if ((this.subContracts && this.subContracts.length > 0) && (this.subContractIds)) {
        subContractFound = this.subContracts.find((subContract) => subContract.id === Number(this.subContractIds));
        if (subContractFound) {
          return (`${(subContractFound.benefit_id ? subContractFound.benefit_id : '-')} - ${(subContractFound.carrier_id && subContractFound.carrier_id.xipp_commercial_name ? subContractFound.carrier_id.xipp_commercial_name : '-')} - ${subContractFound.policy ? subContractFound.policy : '-'} - ${subContractFound.subContract_owner_id && subContractFound.subContract_owner_id.legal_name ? subContractFound.subContract_owner_id.legal_name : '-'}`);
        }
      }
      return subContractFound;
    },
    async onClickSearch(type) {
      const searchParams = this.createSearchParams();
      if (type === 'filter') this.page = 1;
      const filteredParams = this.filterSearchParams(searchParams);
      const insuredData = await this.searchInsureds(filteredParams);
      this.setInsureds(insuredData);
    },
    isFormValid(obj) {
      return Object.keys(obj).length === 0;
    },
    createSearchParams() {
      const contractIdsValidation = typeof this.contractIds === 'number' ? this.contractIds : null;
      const subContractIdsValidation = typeof this.subContractIds === 'number' ? this.subContractIds : null;
      let payload = {};
      const cpfFormated = this.cpf ? this.formatter.removeNonDigit(this.cpf) : null;
      payload = {
        financialGroupIds: this.financialGroupIds && this.financialGroupIds.length > 0 ? this.financialGroupIds : null,
        benefitCodes: this.benefitCodes && this.benefitCodes.length > 0 ? this.benefitCodes : null,
        insuranceCarrierIds: this.insuranceCarrierIds && this.insuranceCarrierIds.length > 0 ? this.insuranceCarrierIds : null,
        contractIds: contractIdsValidation,
        subContractIds: subContractIdsValidation,
        insuredType: this.insuredType ? this.insuredType : null,
        insuredSituation: this.insuredSituation ? this.insuredSituation : null,
        name: this.name ? this.name : null,
        cpf: cpfFormated,
      };
      return payload;
    },
    filterSearchParams(searchParams) {
      return Object.fromEntries(Object.entries(searchParams).filter(([key, value]) => value !== null));
    },
    async searchInsureds(searchParams) {
      if (!this.isFormValid(searchParams)) {
        this.loadingInsureds = true;
        try {
          const params = new URLSearchParams(searchParams);
          params.append('page', `${this.page - 1}`);
          params.append('size', '10');
          params.append('sort', 'id,desc');
          const response = await this.insuredService.FindAllByFilters(params);
          this.totalPages = response.data.totalPages;
          const responseData = response && response.data.content ? response.data.content : [];
          // sessionStorage.setItem('searchParams', JSON.stringify({ ...searchParams, page: this.page, totalPages: this.totalPages }));
          // sessionStorage.setItem('insureds', JSON.stringify(responseData));
          this.setSearchParams({ ...searchParams, page: this.page, totalPages: this.totalPages });
          this.setInsureds(responseData);
          return responseData;
        } catch {
          this.customizeSnackbarMessage('error', 'Ocorreu um erro ao tentar buscar os beneficiários.');
          return [];
        } finally {
          this.loadingInsureds = false;
        }
      } else {
        this.customizeSnackbarMessage('error', 'Por favor, preencha pelo menos um campo de pesquisa.');
        this.page = 1;
        this.totalPages = 0;
        return [];
      }
    },
    onClickBack() {
      this.resetInsuredModule();
      const name = this.isRHProtegido ? 'HomeRHProtegido' : 'HomeAllMenus';
      this.redirectRouter(name, { isRHProtegido: this.isRHProtegido });
    },
    verifyContractForDisableField(val) {
      if (typeof val === 'number') {
        return false;
      }
      return true;
    },
    customizeSnackbarMessage(type, text) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
    },
    getActionsForItem(item) {
      const actions = [
        {
          label: 'Detalhar Beneficiário',
          icon: 'fas fa-search',
          color: 'textPrimary',
          condition: true,
          disabled: false,
          handler: () => this.showDetailInsured(item),
        },
        {
          label: 'Editar Beneficiário',
          icon: 'fa fa-edit',
          color: 'textPrimary',
          condition: this.hasAuthorityEditBeneficiaryDate && !this.isRHProtegido,
          disabled: false,
          handler: () => this.editBeneficiaryDate(item),
        },
      ];

      actions.sort((a, b) => a.label.localeCompare(b.label));

      return actions;
    },
    async verifyCongenericCard(insuranceCarrierId) {     
      await this.odooCarrierService.FindAllByCarrierId(insuranceCarrierId).then((response) => {
        if (response && response.data && response.data.length) {
          this.isDisableCongenericCard = !(response.data[0].xipp_use_similar_card === true);
        }
      });
  }
  },

  computed: {
    ...mapGetters({
      searchParams: 'handler/searchParams',
      insureds: 'insured/insureds',
    })
  },

  created() {
    this.rule = new Rules();
    this.formatter = new Formatter();
    this.insuredService = new InsuredService();
    this.hasAuthorityEditBeneficiaryDate = authorityManager.getAuthorityEditBeneficaryDate();
    this.odooCarrierService = new CarrierService();
  },
};

</script>

<style scoped>
.row-pointer:hover {
  cursor: pointer;
}

.expanded-input {
  transition: width 0.5s;
}
</style>
